<template>
  <div class="form-item required mt-4">
    <label>{{ type == 'standard_tests' ? 'Standard Tests' : 'Microbiological Tests' }}</label>
    <table class="table table-hover table-items" style="background: white; margin-bottom: 6px">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Method</th>
          <th>Price AWG.</th>
          <th>Remove</th>
        </tr>
      </thead>
      <draggable v-model="items" v-bind="dragOptions" @start="drag = true" @end="updateSortOrder" tag="tbody">
        <tr v-for="(item, i) in items" :key="item.id + '-' + i" style="cursor: pointer">
          <td>
            <i class="glyphicon glyphicon-move"></i>
          </td>
          <td>{{ item.name_analysis }}</td>
          <td>{{ item.method }}</td>
          <td>{{ item.price_awg }}</td>
          <td>
            <button class="btn btn-sm" @click.prevent="removeItem(item)" style="box-shadow: none"><i class="glyphicon glyphicon-remove"></i></button>
          </td>
        </tr>
      </draggable>
    </table>
    <v-select
      class="filter-order-number"
      @input="selectSearchedItem"
      v-model="searchQuery"
      :debounce="250"
      :on-search="getTests"
      :options="items_search"
      label="label"
      placeholder="Search analysis item by name to add"
    >
    </v-select>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
export default {
  props: ['type', 'data'],
  components: { draggable },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    }
  },
  data() {
    return {
      items_search: [],
      searchQuery: null,
      items: []
    };
  },
  created() {},
  watch: {
    data(n) {
      if (n.length > 0) this.items = this.data;
    }
  },
  methods: {
    updateSortOrder() {
      this.$emit('update-analysis-items', { items: this.items, type: this.type });
    },
    getTests(q, loading) {
      loading(true);
      this.$store.dispatch('searchAnalysisItems', q).then(data => {
        this.items_search = data.map(item => {
          let obj = {
            label: `${item.name_analysis} | ${item.method} | AWG. ${item.price_awg}`,
            value: item
          };
          return obj;
        });
        loading(false);
      });
    },
    selectSearchedItem(item) {
      if (this.ifItemAlreadyInList(item)) {
        this.reset();
        return;
      }
      this.items.push(item.value);
      this.$emit('update-analysis-items', { items: this.items, type: this.type });

      this.reset();
    },
    removeItem(item) {
      this.items = this.items.filter(itemInLoop => item.id != itemInLoop.id);
      this.$emit('update-analysis-items', { items: this.items, type: this.type });
    },
    ifItemAlreadyInList(item) {
      let alreadyInList = false;
      for (const currentItem of this.items) {
        if (currentItem.id == item.value.id) {
          alreadyInList = true;
          this.$snotify.warning(`Item already in list`, '', {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            position: 'rightTop'
          });
          break;
        }
      }
      return alreadyInList;
    },
    reset() {
      setTimeout(() => {
        this.searchQuery = null;
        this.items_search = [];
      }, 100);
    }
  }
};
</script>

<style></style>
