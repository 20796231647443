<template>
  <div id="selected-labpackage-page">
    <Navigation></Navigation>
    <div class="dashboard-container-right" style="margin-bottom: 300px; padding-bottom: 100px">
      <div v-if="selectedPackage">
        <!-- header -->
        <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px">
          <div style="display: flex">
            <button
              type="button"
              style="
                background: rgba(255, 255, 255, 0.5);
                box-shadow: none;
                font-size: 24px;
                padding: 0px 7px;
                margin-right: 7px;
                color: #0084b6;
                outline: none !important;
              "
              class="btn btn-default pull-left back-button-title mr-2"
              @click="$router.push({ name: 'LabPackagesPage' })"
            >
              <strong><i class="glyphicon glyphicon-chevron-left"></i></strong>
            </button>
            <h2 class="title mt-0">{{ selectedPackage.package_name }}</h2>
          </div>

          <button style="font-size: 12px" class="text-danger btn-danger btn btn-sm" @click="deleteModal()">Delete package</button>
        </div>
        <hr />

        <!-- form -->
        <form ref="userEditForm" class="weblab-form" key="form" @submit.prevent="savePackage">
          <div style="max-width: 600px; margin: 0 auto">
            <!-- image -->
            <div class="form-group">
              <label for="my-file">Image</label>
              <template v-if="!preview">
                <div
                  v-if="selectedPackage.package_image_path"
                  :style="`background-image: url(${$store.state.config.baseUrlApi}/api/private-files/package-images/${selectedPackage.package_image_path})`"
                  style="width: 200px; height: 200px; background-size: cover; background-position: center"
                ></div>
              </template>

              <transition name="fade">
                <div class="" v-if="preview">
                  <div
                    :style="`background-image: url(${preview})`"
                    style="width: 200px; height: 200px; background-size: cover; background-position: center"
                  ></div>
                  <p class="mb-0">file name: {{ image.name }}</p>
                  <p class="mb-0">size: {{ image.size / 1024 }}KB</p>
                  <button @click="reset">Clear</button>
                </div>
              </transition>
              <input v-if="!preview" type="file" accept="image/*" @change="previewImage" class="form-control-file mt-2" id="my-file" />
            </div>

            <!-- package name -->
            <div class="form-item required mt-4">
              <label>Package Name</label>
              <input
                :class="{ error: $v.selectedPackage.package_name.$error }"
                autocorrect="none"
                v-model.trim="$v.selectedPackage.package_name.$model"
                type="text"
                size="60"
                maxlength="60"
                class="form-text"
              />
            </div>
            <!-- package code -->
            <div class="form-item required mt-4">
              <label>Package Code</label>
              <input
                :class="{ error: $v.selectedPackage.package_code.$error }"
                autocorrect="none"
                v-model.trim="$v.selectedPackage.package_code.$model"
                type="text"
                size="60"
                maxlength="60"
                class="form-text"
              />
              <div class="description error" v-if="!$v.selectedPackage.package_code.alphaNum && $v.selectedPackage.package_code.$dirty">
                Only numbers and letters accepted
              </div>
            </div>
            <!-- package description -->
            <div class="form-item required mt-4">
              <label>Package Description</label>
              <textarea
                rows="6"
                class="form-control additional-message"
                :class="{ error: $v.selectedPackage.package_description.$error }"
                autocorrect="none"
                v-model.trim="$v.selectedPackage.package_description.$model"
              >
              </textarea>
            </div>

            <!-- package standard tests -->
            <AnalysisItems type="standard_tests" :data="selectedPackage.standard_tests" @update-analysis-items="updateAnalysisItems" />
            <AnalysisItems type="extra_tests" :data="selectedPackage.extra_tests" @update-analysis-items="updateAnalysisItems" />

            <input type="submit" id="edit-submit" name="op" value="Save" class="button js-form-submit form-submit mt-4" />
          </div>
        </form>

        <p class="text-danger mt-2" v-if="$v.$anyError" style="font-weight: 600; font-size: 14px; text-align: center">
          Please fix any validation errors and try again.
        </p>
      </div>
    </div>

    <!-- delete Modal -->
    <div id="delete-package-modal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-sm">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Delete Item</h4>
          </div>
          <div class="modal-body">Are you sure you want to delete this item?</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" @click="deletePackage">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/generic/Navigation.vue';
import { validationMixin } from 'vuelidate';
import { required, alphaNum } from 'vuelidate/lib/validators';
import * as helpers from '@/helpers/functions.js';
import AnalysisItems from './components/AnalysisItems.vue';

export default {
  mixins: [validationMixin],
  components: { Navigation, AnalysisItems },
  computed: {
    isEdit() {
      return this.$route.params?.packageCode ?? false;
    },
  },
  destroyed: function () {
    helpers.removeModalFromBody('delete-package-modal');
  },
  data() {
    return {
      preview: null,
      image: null,
      selectedPackage: {
        package_name: null,
        package_code: null,
        package_description: null,
        standard_tests: [],
        extra_tests: [],
      },
    };
  },
  validations: {
    selectedPackage: {
      package_name: { required },
      package_code: { required, alphaNum },
      package_description: { required },
    },
  },
  created() {
    if (this.isEdit) this.getSelectedPackage();
  },
  methods: {
    getSelectedPackage() {
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('getSelectedPackage', this.$route.params.packageCode)
        .then((data) => {
          this.selectedPackage = data;
        })
        .catch((error) => helpers.displayError(error, 'getSelectedPackage', this))
        .finally(() => this.$store.commit('setTransLoader', false));
    },
    previewImage(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    savePackage() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      if (!this.checkForImage()) return;
      let payload = new FormData();
      if (this.isEdit) payload.append('id', this.selectedPackage.id);
      payload.append('package_name', this.selectedPackage.package_name);
      payload.append('package_code', this.selectedPackage.package_code);
      payload.append('package_description', this.selectedPackage.package_description);

      const standardTestIds = JSON.stringify(this.selectedPackage.standard_tests.map((item) => item.id));
      const extraTestIds = JSON.stringify(this.selectedPackage.extra_tests.map((item) => item.id));
      payload.append('standard_tests', standardTestIds);
      payload.append('extra_tests', extraTestIds);

      this.$store.commit('setTransLoader', true);
      payload.append('package_image', this.image);
      const actionName = this.isEdit ? 'updatePackage' : 'createPackage';
      this.$store
        .dispatch(actionName, payload)
        .then((data) => {
          if (!data.message) {
            this.units = data;
            this.$snotify.success('Package saved', '', {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              position: 'rightTop',
            });
            this.$router.push({ name: 'LabPackagesPage' });
          } else {
            this.$snotify.error(data.message, '', {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              position: 'rightTop',
            });
          }
        })
        .catch((error) => {
          helpers.displayErrorNoRetry(error?.response?.data?.message ?? 'Error saving package', this);
        })
        .finally(() => {
          this.$store.commit('setTransLoader', false);
          this.selectedIdToDelete = null;
        });
    },
    reset() {
      this.image = null;
      this.preview = null;
    },
    updateAnalysisItems({ items, type }) {
      this.selectedPackage[type] = items;
    },
    deleteModal() {
      jQuery('#delete-package-modal').appendTo('body').modal('show');
    },
    deletePackage() {
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('deletePackage', this.selectedPackage.id)
        .then(() => {
          jQuery('#delete-package-modal').appendTo('body').modal('hide');
          this.$router.push({ name: 'LabPackagesPage' });
          this.$snotify.success('Package deleted', '', {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: 'rightTop',
          });
        })
        .catch((error) => helpers.displayErrorNoRetry(error, this))
        .finally(() => {
          this.$store.commit('setTransLoader', false);
          this.selectedIdToDelete = null;
        });
    },
    checkForImage() {
      if (!this.isEdit && this.preview == null) {
        this.$snotify.warning('Please upload picture', '', {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: 'rightTop',
        });
        return false;
      } else return true;
    },
  },
};
</script>

<style lang="scss">
</style>
